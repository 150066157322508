<template>
  <div class="agm">

    <b-row class="d-flex align-items-center mb-3">
      <b-col cols="12" md="4">
        <b-img fluid rounded src="/misc/bee-bus-annual-pass.jpg" alt="AGM" class="mb-3 mb-md-0" />
      </b-col>
      <b-col cols="12" md="8">
        <h1>Bee Network Annual Bus Pass</h1>
        <h3>From January you can get cheaper annual Bee Network bus travel through your credit union.</h3>
        <p class="mt-3 lead" >The Greater Manchester Credit Unions have teamed up with TfGM and the GM Mayor, Andy Burnham, to make bus travel
          across the city region cheaper and easier to access.</p>
      </b-col>
    </b-row>

    <b-row class="d-flex align-items-center mb-3">
      <b-col cols="12" md="8">

        <p><strong>Introducing the new Annual Bee Network Bus Pass</strong> – allowing bus users to save almost £5 a week, £20 a month
          and £240 a year by purchasing the annual pass through a credit union loan with weekly or monthly
          repayments at no extra cost.</p>

        <p>This means individuals can save money on annual bus travel without having to foot the bill
          upfront. Applications open for the bus pass offer from December 5th and bus passes will be
          issued and in use from 5th January 2025.</p>
        <p>
          <a href="https://news.tfgm.com/press-releases/6d0211f0-b3c7-4db6-8639-e2e12b3cac7b/mayor-announces-plan-for-innovative-scheme-to-make-new-cheaper-annual-bus-ticket-affordable-for-all" target="_blank">Find more information here.</a>
        </p>
      </b-col>
      <b-col cols="12" md="4">
        <b-img fluid rounded src="/misc/bee-bus-network.jpg" alt="AGM" class="mb-3 mb-md-0" />
      </b-col>
    </b-row>


  </div>
</template>

<script>
export default {
  name: 'BeeBusAnnualPass',
  metaInfo() {
    return {
      title: 'Bee Bus Annual Pass | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "bee, bus, network, annual pass, credit union, unions, TfGM, mayour, andy burnham, bus travel" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
